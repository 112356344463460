<template>
    <a-card>
        <a-page-header
            title="平台计划托管"
        />

        <a-form-model
            ref="planDetail"
            :model="planDetail"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="广告计划">
                {{ planDetail.adPlanName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="广告组">
                {{ planDetail.adGroupName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="选择任务" prop="missionId">
                <a-select
                    v-model="planDetail.missionId"
                    placeholder="请选择"
                    show-search
                    option-filter-prop="children"
                    @search="onSearchMission"
                    @change="onChangeMission"
                >
                    <a-select-option v-for="item in taskList" :key="item.missionId">{{ item.missionName }}（{{ item.missionCode }}）</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务周期">
                {{ taskDetail.startTime || '-' }} ~ {{ taskDetail.endTime || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务投放位置">
                <span v-for="(item, index) in taskDetail.placementArr">{{ index !=0 ? '、' : '' }}{{ item }}</span>
            </a-form-model-item>
            <a-form-model-item label="计划投放位置">
                {{ planDetail.placementArr || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务集客场景">
                <a-checkbox-group v-model="taskDetail.customerSceneArr" disabled>
                    <a-checkbox
                        v-for="item in customerSceneList"
                        :key="item.code"
                        :value="item.code"
                    >
                        {{ item.desc }}
                    </a-checkbox>
                </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item label="计划集客场景" prop="customerScene">
                <a-select
                    v-model="planDetail.customerScene"
                    placeholder="请选择"
                    show-search
                    option-filter-prop="children"
                >
                    <a-select-option v-for="item in customerSceneList" :key="item.code">{{ item.desc }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="任务出价类型">
                <a-checkbox-group v-model="taskDetail.bidTypeArr" :options="bidTypeOptions" disabled />
            </a-form-model-item>
            <a-form-model-item label="计划出价类型">
                {{ planDetail.smartBidTypeDesc || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务投放地域">
                <DistrictSelection
                    :cur-value="taskDetail.dyUserTarget"
                    :extensions="taskDetail.extensions"
                    @receive="taskReceive"
                />
            </a-form-model-item>
            <a-form-model-item label="计划投放地域">
                <DistrictSelection
                    :cur-value="planDetail.missionDyTargetDTO"
                    :extensions="planDetail.extensions"
                    @receive="planReceive"
                />
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import { DistrictSelection } from '@bytedance-ad/mui-vue2'

export default {
    components: {
        DistrictSelection,
    },
    data() {
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 16 }
            },
            planDetail: {
                missionId: undefined,
                customerScene: undefined,
                visible: false,
                missionDyTargetDTO: {
                    district: "REGION",
                    city: [],
                },
                extensions: {
                    isV1: true,
                }
            },
            taskDetail: {
                visible: false,
                dyUserTarget: {
                    district: "REGION",
                    city: [],
                },
                extensions: {
                    isV1: true,
                }
            },
            rules: {
                missionId: [
                    { required: true, message: '请选择任务', trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ]
            },
            isSubmit: false,
            taskList: [],
            customerSceneList: [],
            bidTypeOptions: [
                { label: '常规投放', value: 1 },
                { label: '放量投放', value: 2 },
                { label: '最大转化投放', value: 3 },
            ],
        }
    },
    created() {
        this.getCustomerScene()
        this.getTaskList()
        this.getTrustShipDetail()
    },
    methods: {
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        taskReceive(newValue) {
            console.log('taskReceive', {newValue})
            this.taskDetail.dyUserTarget = newValue
        },
        planReceive(newValue) {
            console.log('planReceive', {newValue})
            this.planDetail.missionDyTargetDTO = newValue
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/advertisingPlanManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.planDetail.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    let params = {
                        customerScene: this.planDetail.customerScene,
                        id: this.id,
                        missionId: this.planDetail.missionId
                    }

                    this.$api.core.JLAdvertManage.trusteeAdPlan( params ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/advertisingPlanManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        getTrustShipDetail() {
            this.$api.core.JLAdvertManage.getAdPlanDetail( this.id ).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    _data.customerScene = _data.customerScene ? _data.customerScene : undefined
                    this.$set(this, 'planDetail', _data)
                    if(_data.missionId) {
                        this.getTaskDetail(_data.missionId)
                    }
                } else {
                    this.$message.error(`获取计划详情失败，${res.message}`)
                }
            })
        },
        //获取任务列表
        getTaskList(val) {
            let params = {
                missionName: val,
                page: 1,
                size: 1000
            }
            this.$api.core.deliverManage.getMissionBriefPageQuery(params).then((res) => {
                if(res.code == 200) {
                    this.taskList = res.data.list || []
                } else {
                    this.taskList = []
                    this.$message.error(`获取任务列表失败，${res.message}`)
                }
            })
        },
        onSearchMission(val) {
            this.getTaskList(val)
        },
        onChangeMission() {
            this.getTaskDetail(this.planDetail.missionId)
        },
        //获取任务详情
        getTaskDetail(id) {
            this.$api.core.deliverManage.getTaskDetail(id).then((res) => {
                if(res.code == 200) {
                    this.taskDetail = res.data
                } else {
                    this.$message.error(`获取任务详情失败，${res.message}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
/deep/ .ovui-cascader-panel__selection-list,
/deep/ .ovui-tabs__nav-list,
/deep/ .ovui-cascader-exb__header,
/deep/ .ovui-cascader-exb__close,
/deep/ .ovui-radio-group--divider {
    pointer-events: none;
}
</style>